<template>
    <front-layout>
        <v-container fluid>
            <v-container>
                <v-row class="text-center">
                    <v-col xs="12" sm="12" md="9" lg="9" xl="9" cols="12">
                        <v-row>
                            <v-col v-for="item in arr_developer_card_desktop" :key="'developer_card' + item.id" xs="12"
                                sm="12" md="6" lg="4" xl="4" cols="12">
                                <developer-card :developer="item" class="d-flex flex-column" />
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col v-if="$vuetify.breakpoint.width > 900" md="3" class="pt-0 pr-0 pl-0 pl-md-4">
                        <div class="mt-6 mt-md-0">
                            <aside-link-variable-card />
                        </div>

                        <advertising :limit="2" />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </front-layout>
</template>

<script>
import FrontLayout from "@/layouts/FrontLayout";
import Developer from "@/models/Developer";
import DeveloperCard from "@/components/cards/DeveloperCard";
import AsideLinkVariableCard from "@/components/cards/AsideLinkVariableCard";

export default {
    name: "projects.show",
    components: {
        FrontLayout,
        DeveloperCard,
        AsideLinkVariableCard,
        Advertising: () => import('@/components/ads/Advertising'),
    },
    computed: {
        apartmentsNumber() {
            return this.project.apartments ? this.project.apartments.length : ''
        },
        minPrice() {
            let cheapestApartment = this.$_.minBy(this.project.apartments, 'price');
            return cheapestApartment ? cheapestApartment.price : ''
        }
    },
    data: function () {
        return {
            developers: [],
            arr_developer_card_desktop: [],
        }
    },
    metaInfo() {
        return {
            title: this.$t('meta.developers_title'),
            meta: [
                { name: 'description', content: this.$t('meta.developers_description'), }
            ]
        }
    },
    methods: {
        async init() {
            this.developers = await Developer.get()
            this.arr_developer_card_desktop = this.developers
            let arr_developers = this.developers.slice(0, 10)
            this.developers = this.chunkArray(arr_developers, 2)
        },
        chunkArray(arr, qty) {
            return arr.reduce((prev, cur, i, a) => !(i % qty) ? prev.concat([a.slice(i, i + qty)]) : prev, []);
        },
    },
    mounted() {
        this.init()
    }
}
</script>

<style lang="scss" scoped>

</style>
